<template>
  <div class="aboutUs">
    <div class="bg">
      <img src="../../assets/images/about.jpg" alt="背景图片">
    </div>
    <div class="content">
      <div class="margin">
        <h3>关于我们</h3>
        <div v-html="content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { system } from '@/api/common/public'
export default {
  name: 'aboutUs',
  data() {
    return {
      content: '' // 内容
    }
  },
  created() {
    this.getSystem()
  },
  methods: {
    getSystem() { // 获取系统配置
      system({ key: 'about' }).then(res => {
        this.content = res.data.about;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.aboutUs {
    position: relative;
    .bg{
      img {
        width: 100%;
        height: 320px;
        object-fit: cover;
      }
    }
    .content {
      width: 1200px;
      min-height: 570px;
      margin: -100px auto 20px;
      background: #fff;
      .margin{
        background: #fff;
        display: inline-block;
        width: 100%;
        min-height: 570px;
      }
      h3 {
        text-align: center;
        &:after {
          content: "";
          margin: 15px auto 0 auto;
          display: block;
          width: 32px;
          height: 5px;
          background: #1166CF;
          border-radius: 3px;
        }
      }
      div {
        padding: 0 30px;
      }
    }
  }
</style>
